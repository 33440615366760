import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from "./slices/auth/authSlice";
import { catalogueSlice } from './slices/catalogue/catalogueSlice';
import { filesSlice } from './slices/files/filesSlice';
import { groupFilesSlice } from './slices/groupFiles/groupFilesSlice';
import { generalSlice } from './slices/general/generalSlice';
import { quotesSlice } from './slices/quotes/quotesSlice';
import { individualFilesSlice } from './slices/individualFiles/individualFilesSlice';
import { guestSlice } from './slices/guest/guestSlice';
import { groupSlice } from './slices/group/groupSlice';
import { travelerSlice } from './slices/traveler/travelerSlice';
import { representativeSlice } from './slices/representative/representativeSlice';
import { tutorialsSlice } from './slices/tutorials/tutorialsSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    catalogue: catalogueSlice.reducer,
    files: filesSlice.reducer,
    groupFiles: groupFilesSlice.reducer,
    individualFiles: individualFilesSlice.reducer,
    quotes: quotesSlice.reducer,
    general: generalSlice.reducer,
    guest: guestSlice.reducer,
    group: groupSlice.reducer,
    traveler: travelerSlice.reducer,
    representative: representativeSlice.reducer,
    tutorials: tutorialsSlice.reducer
  },
})