import { useDispatch, useSelector } from "react-redux";
import { downloadCancellationPolicy, downloadModificationPolicy } from "../../../store/slices/traveler/thunks";

export const PoliciesScreen = () => {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.traveler);  

  return (
    <div className="mt-5">
      <div>
        {      
          info.modificationPolicy 
          ? <button className="btn btn-light btn-info mt-2" 
            onClick={() => dispatch(downloadModificationPolicy(info.modificationPolicy, info.code))}>Descargar Politica De Modificacion</button>
          : <div style={{fontSize: 16}}>Tu política de modificacíon aun no esta disponible</div>        
        } 
      </div>
      <div>
        {
          info.cancellationPolicy 
          ? <button className="btn btn-light btn-info mt-2" 
            onClick={() => dispatch(downloadCancellationPolicy(info.cancellationPolicy, info.code))}>Descargar Politica De Cancelacion</button>
          : <div style={{fontSize: 16}}>Tu política de cancelacíon aun no esta disponible</div>
        } 
      </div>
    </div>
  )
}
