import { createSlice } from '@reduxjs/toolkit';

export const guestSlice = createSlice({
  name: 'guest',
  initialState: {    
    info: {
      masterSale: {      
        groupRooms: [],
        services: [],
        air: [],
        lodging: [],
        cruise: [],
        service: [],
        advancePayments: [],
        rejectedPayments: [],
      },
    },
    transportRequests:[],
    hotelImages:[],
    specialRequests:[],
    isLoading: false,
  },
  reducers: {   
    setInfo: (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    }, 
    setTransportRequests: (state, action) => {
      state.transportRequests = action.payload;
    }, 
    setHotelImages: (state, action) => {
      state.hotelImages = action.payload;
    },   
    setSpecialRequests: (state, action) => {
      state.specialRequests = action.payload;
    },   
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  }
});


// Action creators are generated for each case reducer function
export const { setInfo, setTransportRequests, setHotelImages, setSpecialRequests, setIsLoading
  } = guestSlice.actions;