import { useSelector } from "react-redux";

export const ReligiousScreen = () => {
  const { info } = useSelector((state) => state.group);

  return (
    <div className="mt-5">
      {info.religiousCeremony.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))}
    </div>
  )
}
