import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentMethodList, getWholesalersList } from '../../../store/slices/general/thunks';
import { MasterSaleDetails } from './MasterSaleDetails';
import { Header } from '../Header/Header';
import { AccountsPayable } from './AccountsPayable';
import { AdvancePayment } from './AdvancePayment';
import { PaymentToWholesaler } from './PaymentToWholesaler';
import { MoneyFlow } from './MoneyFlow';
import { getMasterSaleByFile } from '../../../store/slices/files/thunks';
import { setOptionSelected } from '../../../store/slices/files/filesSlice';
import { RejectedPayment } from './RejectedPayment';
import { Documents } from '../Documents/Documents';


export const MasterSale = ({ file }) => {
  const dispatch = useDispatch();
  const { masterSale } = useSelector((state) => state.files);

  useEffect(() => {
    if (file.id) {
      dispatch(getMasterSaleByFile(file.id))
    }    
  }, [file.id])

  useEffect(() => {
    dispatch(getWholesalersList())
  }, [])

  useEffect(() => {
    dispatch(getPaymentMethodList())
  }, [])

  useEffect(() => {
    if (masterSale.isOpen) {
      dispatch(setOptionSelected("Quotes"))
    }
  }, [masterSale.isOpen])


  return (
    <div>
      <Header file={file} />
      <Documents file={file} />
      <MasterSaleDetails masterSale={masterSale} file={file} />

      <AdvancePayment masterSale={masterSale} code={file.code} />
      <RejectedPayment masterSale={masterSale} code={file.code} />
      <PaymentToWholesaler masterSale={masterSale} />
      <MoneyFlow masterSale={masterSale} />
      <AccountsPayable masterSale={masterSale} />

    </div>
  )
}
