import { Button, Modal} from "react-bootstrap";
import styles from './ViewImages.module.css';
import { Gallery } from "../ui/Gallery";
import { useState } from "react";

export const ViewImages = ({ images, isOpen, setIsOpen }) => {    
  const [hide, setHide] = useState(false);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
        className={hide && styles.modal}
      >
        <Modal.Header>
          <Modal.Title >
            Imagenes
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>


        <Modal.Body>
          {
            isOpen &&
            <Gallery images={images} setIsOpen={setIsOpen} setHide={setHide}/>
          }          
        </Modal.Body>

        <Modal.Footer>
        </Modal.Footer>


      </Modal>
    </>
  );
}
