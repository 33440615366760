import React, { useState } from 'react'
import { AddAdvancePayment } from './AddAdvancePayment';
import { AdvancePaymentDetails } from './AdvancePaymentDetails';

export const AdvancePayment = ({masterSale, code}) => {
  const [addAdvancePayment, setAddAdvancePayment] = useState(false);

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Pagos de cliente</div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-light btn-icon" style={{ marginTop: 10 }}
          onClick={() => setAddAdvancePayment(true)} >Agregar Pago</button>
      </div>

      <AdvancePaymentDetails masterSale={masterSale} code={code} />
      <AddAdvancePayment masterSale={masterSale} isOpen={addAdvancePayment} setIsOpen={setAddAdvancePayment} />
    </div>
  )
}
