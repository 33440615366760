import { viajesApi } from "../../../api/viajesApi";
import { setFilesList, setFile, setPassengersList, setMasterSale, setOptionSelected, resetMasterSale, 
  setPendingPayments, 
  setPendingSpecialRequest} from "./filesSlice";
import Swal from "sweetalert2";
import fileDownload from 'js-file-download'

export const addFile = (idCustomer, idCategory, tags, requestDate, startDate, endDate, paydayLimit, adults, juniors,
  minors, infants, notes, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/files/addFile", {
        idCustomer, idCategory, tags, requestDate, startDate, endDate, paydayLimit, adults, juniors,
        minors, infants, notes
      });
      success();
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      console.log(error.response)
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const editFile = (id, idCustomer, idCategory, tags, requestDate, startDate, endDate, paydayLimit, adults, juniors,
  minors, infants, notes, remove, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/files/editFile", {
        id, idCustomer, idCategory, tags, requestDate, startDate, endDate, paydayLimit, adults, juniors,
        minors, infants, notes, remove
      });
      success();
      dispatch(getFileById(id));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      console.log(error.response)
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}


export const getFilesList = (file, customer, reserve, month, tags) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/search", {
        params: {
          file, customer, reserve, month, tags
        }
      });      
      let files = resp.data.results.map((x) => ({...x, id:x.id +x.group2, id2:x.id}));      
      dispatch(setFilesList(files));
    } catch (error) {

    }
  }
}

export const getFileById = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/byId", {
        params: {
          id
        }
      });
      dispatch(setFile(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
      dispatch(setFile({id:-1}));
    }
  }
}

export const addPassenger = (name, lastName, mothersLastName, relationship, birthdate, idFile, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/files/addPassenger", {
        idFile, name, lastName, mothersLastName, relationship, birthdate,
      });
      success();
      dispatch(getPassengersByIdFile(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const getPassengersByIdFile = (idFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/passengersByIdFile", {
        params: {
          idFile
        }
      });
      dispatch(setPassengersList(resp.data.results));
    } catch (error) {

    }
  }
}

export const deletePassengerFile = (id, idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/deletePassengerFile", { params: { id } });
      success();
      dispatch(getPassengersByIdFile(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const editPassengerFile = (id, name, lastName, mothersLastName, relationship, birthdate, idFile,
  success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/files/editPassenger", {
        id, name, lastName, mothersLastName, relationship, birthdate
      });
      success();
      dispatch(getPassengersByIdFile(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const getMasterSaleByFile = (idFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/masterSaleByFile", {
        params: {
          idFile
        }
      });
      dispatch(setMasterSale(resp.data.results));
    } catch (error) {
      dispatch(resetMasterSale());
    }
  }
}

export const addPaymentMasterSale = (idMasterSaleFile, date, amount, idPaymentWay, reference, image,
  invoice, notes, idFile, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idMasterSaleFile", idMasterSaleFile);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("invoice", invoice);
      form.append("notes", notes ? notes : "");

      const resp = await viajesApi.post("/files/addPaymentMasterSale", form);
      success();
      dispatch(getMasterSaleByFile(idFile));
      dispatch(getFileById(idFile));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const editPaymentMasterSale = (id, date, amount, idPaymentWay, reference, image, 
  invoice, notes, approved, idFile, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("invoice", invoice);
      form.append("notes", notes ? notes : "");
      form.append("approved", approved);

      const resp = await viajesApi.put("/files/editPaymentMasterSale", form);
      success();
      dispatch(getMasterSaleByFile(idFile));
      dispatch(getFileById(idFile));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const deletePaymentMasterSale = (id,
  idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/deletePaymentMasterSale", {
        params: { id }
      });
      success();
      dispatch(getMasterSaleByFile(idFile));
      dispatch(getFileById(idFile));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const rejectPaymentMasterSale = (id, notes, idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/rejectPaymentMasterSale", {
        params: { id, notes }
      });
      success();
      dispatch(getMasterSaleByFile(idFile));
      dispatch(getFileById(idFile));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const addPaymentWholesaler = (id, concept, date, amount, idPaymentWay, notes,
  idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/files/addPaymentWholesaler", {
        id, concept, date, amount, idPaymentWay, notes
      });
      success();
      dispatch(getMasterSaleByFile(idFile));
      dispatch(getFileById(idFile));
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const editPaymentWholesaler = (id, concept, date, amount, idPaymentWay, notes,
  idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/files/editPaymentWholesaler", {
        id, concept, date, amount, idPaymentWay, notes
      });
      success();
      dispatch(getMasterSaleByFile(idFile));
      dispatch(getFileById(idFile));
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const deletePaymentWholesaler = (id, concept,
  idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/deletePaymentWholesaler", {
        params: { id, concept }
      });
      success();
      dispatch(getMasterSaleByFile(idFile));
      dispatch(getFileById(idFile));
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const openCloseMasterSale = (idMasterSaleFile, isOpen, idFile) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/files/openCloseMasterSale", {
        idMasterSaleFile, isOpen,
      });
      if (isOpen) {
        dispatch(setOptionSelected("Quotes"))
      } else {
        dispatch(setOptionSelected("MasterSale"))
      }
      dispatch(getMasterSaleByFile(idFile))
    } catch (error) {
    }
  }
}

export const travelVoucher = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/travelVoucherById", {
        params: {
          id
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Recibo de viaje.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getPendingPayments = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/pendingPayments", );
      dispatch(setPendingPayments(resp.data.results));
    } catch (error) {

    }
  }
}

export const editPendingPayment = (id, date, amount, idPaymentWay, reference, image, 
  invoice, notes, approved, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("invoice", invoice);
      form.append("notes", notes ? notes : "");
      form.append("approved", approved);

      const resp = await viajesApi.put("/files/editPaymentMasterSale", form);
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const deletePendingPayment = (id, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/deletePaymentMasterSale", {
        params: { id }
      });
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const rejectPendingPayment = (id, notes, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/rejectPaymentMasterSale", {
        params: { id, notes }
      });
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const downloadPayments = (idFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/downloadPayments", {
        params: {
          idFile
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Pagos.zip`);
      Swal.fire("Archivos", "Pagos descargados correctamente", "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const addContract = (idFile, contract, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idFile", idFile);
      if (contract)
        form.append("contract", contract, contract.Name);

      const resp = await viajesApi.post("/files/addContract", form);
      success();
      dispatch(getFileById(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const addModificationPolicy = (idFile, policy, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idFile", idFile);
      if (policy)
        form.append("policy", policy, policy.Name);

      console.log("1")

      const resp = await viajesApi.post("/files/addModificationPolicy", form);
      success();
      dispatch(getFileById(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const addCancellationPolicy = (idFile, policy, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idFile", idFile);
      if (policy)
        form.append("policy", policy, policy.Name);

      const resp = await viajesApi.post("/files/addCancellationPolicy", form);
      success();
      dispatch(getFileById(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const deleteContract = (idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/deleteContract", {
        params: { idFile }
      });

      success();
      dispatch(getFileById(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const deleteModificationPolicy = (idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/deleteModificationPolicy", {
        params: { idFile }
      });

      success();
      dispatch(getFileById(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const deleteCancellationPolicy = (idFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/files/deleteCancellationPolicy", {
        params: { idFile }
      });

      success();
      dispatch(getFileById(idFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const downloadContract = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/downloadContract", {
        params: {
          id
        },
        responseType: 'blob',
      });

      fileDownload(resp.data, `Contrato.pdf`);
      Swal.fire("Archivos", "Contrato descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadModificationPolicy = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/downloadModificationPolicy", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Politica de modificacion.pdf`);
      Swal.fire("Archivos", "Politica de modificacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadCancellationPolicy = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/downloadCancellationPolicy", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Politica de cancelacion.pdf`);
      Swal.fire("Archivos", "Politica de cancelacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getPendingSpecialRequest = () => {
  return async (dispatch, getState) => {
    try {      
      const resp = await viajesApi.get("/files/pendingSpecialRequest",);
      dispatch(setPendingSpecialRequest(resp.data.results));
    } catch (error) {

    }
  }
}

export const editSpecialRequest = (id, answer, statusRequest, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/files/editSpecialRequest", {
        id, answer, statusRequest
      });
      success();
      dispatch(getPendingSpecialRequest());
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}