import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {  useDispatch } from 'react-redux';
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";
import { editPaymentsTutorialImage } from "../../../store/slices/tutorials/thunks";
import { MyFileInput } from "../../ui/controls/MyFileInput";
import Swal from "sweetalert2";
import { Image_Type } from "../../../const/Image";

export const EditPaymentsTutorialImage = ({image, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Imagen
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            text: image.text,            
          }}
          onSubmit={(({ text, image2, }, { resetForm, setErrors }) => {

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editPaymentsTutorialImage(image.id, image2, text, success));

          })}
          validationSchema={
            Yup.object({
              text: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyFileInput label='Imagen:' name='image' mode='horizontal'
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                if (Image_Type.indexOf(e.target.files[0].type) === -1) {
                                  setFieldValue("image2", undefined)
                                  return Swal.fire("Imagenes", "Formato no valido", "error");
                                }
                                if (e.target.files[0].size > 2000000) {
                                  setFieldValue("image2", undefined)
                                  return Swal.fire("Imagenes", "Solo se permiten imagenes de 2MB max", "error");
                                }
                                setFieldValue("image2", e.currentTarget.files[0])
                              }
                            }}
                          />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Texto:' name='text' mode='horizontal' rows={4} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
