import React from "react";
import { LightgalleryProvider, LightgalleryItem, useLightgallery, LinesEllipsis } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";


const OpenButtonWithHook = props => {
  const { openGallery } = useLightgallery();
  return (
    <button
      {...props}
      onClick={() => openGallery("group1")}
      className={["button is-primary", props.className || ""].join(" ")}
    >
      Open second photos group (using hook)
    </button>
  );
};

const PhotoItem = ({ image, group, text }) => (
  <div style={{ maxWidth: "250px", width: "200px", maxHeight: "200px", padding: "5px" }}>
    <LightgalleryItem group={group} src={image} download={false}>
      <img className="img-responsive br-5" src={image} alt="" style={{ width: "100%" }} />
      <label>{text}</label>    
    </LightgalleryItem>
  </div>
);



export const Gallery = ({ images, viewImages, setIsOpen, setHide }) => {


  return (
    <div id="lightgallery" className="list-unstyled row">
      <LightgalleryProvider
        // onBeforeOpen={() => console.info("onBeforeOpen")}
        // onAfterOpen={() => console.info("onAfterOpen")}
        // onSlideItemLoad={() => console.info("onSlideItemLoad")}
        onBeforeSlide={() => setHide(true)}
        //onAfterSlide={}
        // onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
        // onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
        // onDragstart={() => console.info("onDragstart")}
        // onDragmove={() => console.info("onDragmove")}
        // onDragend={() => console.info("onDragend")}
        // onSlideClick={() => console.info("onSlideClick")}
        onBeforeClose={() => setHide(false)}
        //onCloseAfter={}
      >
        {images.map(image => {
          return (
            <div className="col-xs-6 col-sm-4 col-md-4 col-xl-4 mb-5 border-bottom-0" key={image.image}>
              <PhotoItem
                image={image.image}
                group="group1"
                text={image.text}
              />
            </div>
          );
        })}
      </LightgalleryProvider>
    </div>
  );
}


