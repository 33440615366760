import React from 'react'
import { AdvancePayment } from './AdvancePayment'
import { IndividualFiles } from './IndividualFiles'
import { MasterSale } from './MasterSale'
import { RejectedPayment } from './RejectedPayment'
import { Romming } from './Romming'
import { PeopleControl } from './PeopleControl'

export const Details = ({ info }) => {
  return (
    <div key={info.id} style={{ marginTop: 20, marginBottom: 50, border: "1px solid #DCDCDC", background: "#F9F9F9", padding: 20 }}>
      <div style={{ fontSize: 22 }}>{info.group2}</div>
      <AdvancePayment masterSale={info.masterSale} code={info.code} paydayLimit={info.paydayLimit} paydayLimitDays={info.paydayLimitDays} />
      <RejectedPayment masterSale={info.masterSale} code={info.code} />
      <MasterSale masterSale={info.masterSale} />
      <IndividualFiles files={info.allFiles} />
      <Romming hotels={info.hotels} code={info.code} />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <PeopleControl peopleSummary={info.peopleSummary} />
      </div>
    </div>
  )
}
