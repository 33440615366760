import React, { useState } from 'react'
import { AddAdvancePayment } from './AddAdvancePayment';
import { AdvancePaymentDetails } from './AdvancePaymentDetails';

export const AdvancePayment = ({masterSaleGroup, code, ref2}) => {
  const [addAdvancePayment, setAddAdvancePayment] = useState(false);

  return (
    <div style={{marginTop:20}} ref={ref2}>
      <div style={{ fontSize: 20 }}>Anticipos de grupo</div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-light btn-icon" style={{ marginTop: 10 }}
          onClick={() => setAddAdvancePayment(true)} >Agregar Anticipo De Grupo</button>
      </div>

      <AdvancePaymentDetails masterSaleGroup={masterSaleGroup} code={code} />
      <AddAdvancePayment masterSaleGroup={masterSaleGroup} isOpen={addAdvancePayment} setIsOpen={setAddAdvancePayment} />
    </div>
  )
}
