import { useDispatch, useSelector } from 'react-redux';
import { getPaymentsTutorial } from '../../store/slices/traveler/thunks';
import styles from './Header.module.css'
import { useState } from 'react';
import { PaymentsTutorial } from '../ui/tutorials/payment/PaymentsTutorial';


export const Header = ({info}) => {
  const dispatch = useDispatch();
  const [ viewPaymentsTutorial, setViewPaymentsTutorial ] = useState(false);
  const { paymentsTutorial } = useSelector((state) => state.tutorials);

  const showPaymentsTutorial = () => {
    dispatch(getPaymentsTutorial());
    setViewPaymentsTutorial(true);
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 20 }}>
      <div className={`${styles.title}`}>Categoria: {info.category}</div>
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Cliente:</label><label>{info.customer}</label>
          </div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Destino:</label><label>{info.tags}</label>
          </div>
        </div>
        <div>
            <button className={`${styles.images} btn btn-info`} onClick={showPaymentsTutorial}>Tutorial de pago</button>
        </div>
      </div>
      <PaymentsTutorial paymentsTutorial={paymentsTutorial} isOpen={viewPaymentsTutorial} setIsOpen={setViewPaymentsTutorial} />
    </div>
  )
}
