import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentMethodList, getWholesalersList } from '../../../../store/slices/general/thunks';
import { MasterSaleDetails } from './MasterSaleDetails';
import { getMasterSaleByIndividualFile } from '../../../../store/slices/individualFiles/thunks';
import { Header } from '../Header/Header';
import { AccountsPayable } from './AccountsPayable';
import { GroupSales } from './GroupSales';
import { AdvancePayment } from './AdvancePayment';
import { PaymentToWholesaler } from './PaymentToWholesaler';
import { MoneyFlow } from './MoneyFlow';
import { setOptionSelected } from '../../../../store/slices/individualFiles/individualFilesSlice';
import { RejectedPayment } from './RejectedPayment';


export const MasterSale = ({ individualFile }) => {
  const dispatch = useDispatch();
  const { masterSale } = useSelector((state) => state.individualFiles);

  useEffect(() => {
    if (individualFile.id) {
      dispatch(getMasterSaleByIndividualFile(individualFile.id))
    }
  }, [individualFile.id])

  useEffect(() => {
    dispatch(getWholesalersList())
  }, [])

  useEffect(() => {
    dispatch(getPaymentMethodList())
  }, [])

  useEffect(() => {
    if (masterSale.isOpen) {
      dispatch(setOptionSelected("Quotes"))
    }
  }, [masterSale.isOpen])



  return (
    <div>
      <Header individualFile={individualFile} />
      <MasterSaleDetails masterSale={masterSale} individualFile={individualFile} />

      <AdvancePayment masterSale={masterSale} code={individualFile.code} />
      <RejectedPayment masterSale={masterSale} code={individualFile.code} />
      <PaymentToWholesaler masterSale={masterSale} />
      <MoneyFlow masterSale={masterSale} />
      <AccountsPayable masterSale={masterSale} />
      <GroupSales masterSale={masterSale} />

    </div>
  )
}
