import React, { useState } from 'react'
import { PaymentToWholesalerDetails } from './PaymentToWholesalerDetails';

export const PaymentToWholesaler = ({masterSaleGroup, code, ref2}) => {

  return (
    <div style={{marginTop:20}} ref={ref2}>
      <div style={{ fontSize: 20 }}>Pagos a mayoristas</div>

      <PaymentToWholesalerDetails masterSaleGroup={masterSaleGroup} code={code} />
    </div>
  )
}
