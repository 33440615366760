import { useState } from "react";
import { Table } from "react-bootstrap";
import styles from './SeatingPlanDetails.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { AddGuest } from "./AddGuest";
import { DeleteTable } from "./DeleteTable";
import { EditGuest } from "./EditGuest";


export const SeatingPlanDetails = ({ seatingPlan, code, idGroupFile }) => {
  const { guestHighlighted, externalGuestHighlighted } = useSelector((state) => state.group);
  const [editGuest, setEditGuest] = useState(false);
  const [chairSelected, setChairSelected] = useState({});
  const [addGuest, setAddGuest] = useState(false);
  const [deleteTable, setDeleteTable] = useState(false);
  const [tableToDelete, setTableToDelete] = useState({});

  const addGuest2 = (chair) => {
    setAddGuest(true);
    setChairSelected(chair);
  }

  const editGuest2 = (idChair, idGuest, guest, idExternalGuest, externalGuest) => {
    setEditGuest(true);
    setChairSelected({
      idChair, idGuest, guest, idExternalGuest, externalGuest
    });
  }

  const deleteTable2 = (id, num) => {
    setDeleteTable(true);
    setTableToDelete({
      id, num
    });
  }

  const renderTable = (table, index) => {
    switch (table.chairs.length) {
      case 20:
        return (
          <div className={`${styles.table_20} ${index % 2 == 0 ? styles.tableLeft : styles.tableRight}`}>
            <img className={styles.table2_20} src={require("../../../assets/images/draws/chair20.png")} alt="avatar" />
            {
              table.chairs.map((chair, index) => (
                chair.idGuest
                  ? <div key={chair.id}>
                    <img className={`${styles.guest} ${styles["guest" + (index + 1) + "-20"]}`}
                      src={require(`../../../assets/images/draws/${chair.idGuest == guestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                      alt="avatar" />
                    <label className={`${styles.guestName} ${styles["guestName" + (index + 1) + "-20"]} ${chair.idGuest == guestHighlighted ? styles.highlighted : null} `}
                      onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.guest}</label>
                  </div>
                  : chair.idExternalGuest
                    ? <div key={chair.id}>
                      <img className={`${styles.guest} ${styles["guest" + (index + 1) + "-20"]}`}
                        src={require(`../../../assets/images/draws/${chair.idExternalGuest == externalGuestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                        alt="avatar" />
                      <label className={`${styles.externalGuestName} ${styles["guestName" + (index + 1) + "-20"]} ${chair.idExternalGuest == externalGuestHighlighted ? styles.highlighted : null} `}
                        onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.externalGuest}</label>
                    </div>
                    : <img key={chair.id} className={`${styles.add} ${styles["add" + (index + 1) + "-20"]}`} src={require("../../../assets/images/draws/plus2.png")} alt="avatar" onClick={() => addGuest2(chair.id)} />
              ))
            }
            <label className={`${styles.tableName} ${styles.tableName_20}`}>Mesa {index + 1}</label>
            <label className={`${styles.tableDelete} ${styles.tableDelete_20}`} onClick={() => deleteTable2(table.id, index + 1)}>Eliminar</label>
          </div>
        );
      case 16:
        return (
          <div className={`${styles.table_16} ${index % 2 == 0 ? styles.tableLeft : styles.tableRight}`}>
            <img className={styles.table2_16} src={require("../../../assets/images/draws/chair16.png")} alt="avatar" />
            {
              table.chairs.map((chair, index) => (
                chair.idGuest
                  ? <div key={chair.id}>
                    <img className={`${styles.guest} ${styles["guest" + (index + 1) + "-16"]}`}
                      src={require(`../../../assets/images/draws/${chair.idGuest == guestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                      alt="avatar" />
                    <label className={`${styles.guestName} ${styles["guestName" + (index + 1) + "-16"]} ${chair.idGuest == guestHighlighted ? styles.highlighted : null} `}
                      onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.guest}</label>
                  </div>
                  : chair.idExternalGuest
                    ? <div key={chair.id}>
                      <img className={`${styles.guest} ${styles["guest" + (index + 1) + "-16"]}`}
                        src={require(`../../../assets/images/draws/${chair.idExternalGuest == externalGuestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                        alt="avatar" />
                      <label className={`${styles.externalGuestName} ${styles["guestName" + (index + 1) + "-16"]} ${chair.idExternalGuest == externalGuestHighlighted ? styles.highlighted : null} `}
                        onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.externalGuest}</label>
                    </div>
                    : <img key={chair.id} className={`${styles.add} ${styles["add" + (index + 1) + "-16"]}`} src={require("../../../assets/images/draws/plus2.png")} alt="avatar" onClick={() => addGuest2(chair.id)} />
              ))
            }
            <label className={`${styles.tableName} ${styles.tableName_16}`}>Mesa {index + 1}</label>
            <label className={`${styles.tableDelete} ${styles.tableDelete_16}`} onClick={() => deleteTable2(table.id, index + 1)}>Eliminar</label>
          </div>
        );
      case 12:
        return (
          <div className={`${styles.table_12} ${index % 2 == 0 ? styles.tableLeft : styles.tableRight}`}>
            <img className={styles.table2_12} src={require("../../../assets/images/draws/chair4.png")} alt="avatar" />
            {
              table.chairs.map((chair, index) => (
                chair.idGuest
                  ? <div key={chair.id}>
                    <img className={`${styles.guest} ${styles["guest" + (index + 1) + "-12"]}`}
                      src={require(`../../../assets/images/draws/${chair.idGuest == guestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                      alt="avatar" />
                    <label className={`${styles.guestName} ${styles["guestName" + (index + 1) + "-12"]} ${chair.idGuest == guestHighlighted ? styles.highlighted : null} `}
                      onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.guest}</label>
                  </div>
                  : chair.idExternalGuest
                    ? <div key={chair.id}>
                      <img className={`${styles.guest} ${styles["guest" + (index + 1) + "-12"]}`}
                        src={require(`../../../assets/images/draws/${chair.idExternalGuest == externalGuestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                        alt="avatar" />
                      <label className={`${styles.externalGuestName} ${styles["guestName" + (index + 1) + "-12"]} ${chair.idExternalGuest == externalGuestHighlighted ? styles.highlighted : null} `}
                        onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.externalGuest}</label>
                    </div>
                    : <img key={chair.id} className={`${styles.add} ${styles["add" + (index + 1) + "-12"]}`} src={require("../../../assets/images/draws/plus2.png")} alt="avatar" onClick={() => addGuest2(chair.id)} />
              ))
            }
            <label className={`${styles.tableName} ${styles.tableName_12}`}>Mesa {index + 1}</label>
            <label className={`${styles.tableDelete} ${styles.tableDelete_12}`} onClick={() => deleteTable2(table.id, index + 1)}>Eliminar</label>
          </div>
        );

      default: // For 10 seats
        return (
          <div className={`${styles.table} ${index % 2 == 0 ? styles.tableLeft : styles.tableRight}`}>
            <img className={styles.table2} src={require("../../../assets/images/draws/chair3.png")} alt="avatar" />
            {
              table.chairs.map((chair, index) => (
                chair.idGuest
                  ? <div key={chair.id}>
                    <img className={`${styles.guest} ${styles["guest" + (index + 1)]} `}
                      src={require(`../../../assets/images/draws/${chair.idGuest == guestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                      alt="avatar" />
                    <label className={`${styles.guestName} ${styles["guestName" + (index + 1)]} ${chair.idGuest == guestHighlighted ? styles.highlighted : null} `}
                      onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.guest}</label>
                  </div>
                  : chair.idExternalGuest
                    ? <div key={chair.id}>
                      <img className={`${styles.guest} ${styles["guest" + (index + 1)]} `}
                        src={require(`../../../assets/images/draws/${chair.idExternalGuest == externalGuestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                        alt="avatar" />
                      <label className={`${styles.externalGuestName} ${styles["guestName" + (index + 1)]} ${chair.idExternalGuest == externalGuestHighlighted ? styles.highlighted : null} `}
                        onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.externalGuest}</label>
                    </div>
                    : <img key={chair.id} className={`${styles.add} ${styles["add" + (index + 1)]}`} src={require("../../../assets/images/draws/plus2.png")} alt="avatar" onClick={() => addGuest2(chair.id)} />
              ))
            }
            <label className={`${styles.tableName} ${styles.tableName_10}`}>Mesa {index + 1}</label>
            <label className={`${styles.tableDelete} ${styles.tableDelete_10}`} onClick={() => deleteTable2(table.id, index + 1)}>Eliminar</label>
          </div>
        );
    }
  };

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20, minWidth: "1150px" }}>
      <div className={`${styles.staticTable} ${styles.coupleTable}`}>
        MESA DE NOVIOS
      </div>
      <div className={`${styles.tables} row`}>
        {
          seatingPlan.map((table, index) => (
            <div key={table.id} style={{ marginBottom: "200px" }} className={`col-6`}>
              {renderTable(table, index)}
            </div>
          ))
        }
      </div>
      <div className={`${styles.staticTable} ${styles.musicTable}`}>
        DJ O MUSICA EN VIVO
      </div>
      <AddGuest chair={chairSelected} code={code} idGroupFile={idGroupFile}
        isOpen={addGuest} setIsOpen={setAddGuest} />
      <EditGuest chair={chairSelected} code={code} idGroupFile={idGroupFile}
        isOpen={editGuest} setIsOpen={setEditGuest} />
      <DeleteTable idGroupFile={idGroupFile} code={code} isOpen={deleteTable} setIsOpen={setDeleteTable} table={tableToDelete} />
    </div>
  )
}
