import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPendingSpecialRequest as getPendingSpecialRequestGroupFiles } from '../../../store/slices/groupFiles/thunks';
import { getPendingSpecialRequest as getPendingSpecialRequestIndividualFiles } from '../../../store/slices/individualFiles/thunks';
import { getPendingSpecialRequest as getPendingSpecialFiles } from '../../../store/slices/files/thunks';
import { SpecialRequestDetails } from './SpecialRequestDetails';

export const SpecialRequest = () => {
  const dispatch = useDispatch();
  const { pendingSpecialRequest: pendingSpecialRequestGroupFile } = useSelector((state) => state.groupFiles);
  const { pendingSpecialRequest: pendingSpecialRequestIndividualFile } = useSelector((state) => state.individualFiles);
  const { pendingSpecialRequest: pendingSpecialRequestFile } = useSelector((state) => state.files);
  
  useEffect(() => {
    dispatch(getPendingSpecialRequestGroupFiles())
  }, [])

  useEffect(() => {
    dispatch(getPendingSpecialRequestIndividualFiles())
  }, [])

  useEffect(() => {
    dispatch(getPendingSpecialFiles())
  }, [])
  

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Solicitudes pendientes por aprobar</div>
      <SpecialRequestDetails pendingSpecialRequestGroupFile={pendingSpecialRequestGroupFile} 
        pendingSpecialRequestIndividualFile={pendingSpecialRequestIndividualFile}
        pendingSpecialRequestFile={pendingSpecialRequestFile} />
    </div>
  )
}
