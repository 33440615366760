import { useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../../helpers/numericFields";
import styles from './AdvancePaymentDetails.module.css'
import { ModalPaymentImage } from "../../../ui/controls/ModalPaymentImage";

export const RejectedPaymentDetails = ({ masterSale, code }) => {
  const [viewImage, setViewImage] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState({});

  const viewImage2 = (payment) => {
    setViewImage(true);
    setPaymentSelected(payment)
  }


  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Pago #</th>
              <th>Fecha</th>
              <th>Agente</th>
              <th>Metodo</th>
              <th>Forma De Pago</th>
              <th>Referencia</th>
              <th>Evidencia</th>
              <th>Monto</th>
              <th>Notas</th>              
            </tr>
          </thead>
          <tbody>
            {
              masterSale.rejectedPayments.map((payment, index) => (
                <tr key={payment.id}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.agent}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>
                  <td>{payment.reference}</td>
                  <td style={{ textAlign: "center" }}>
                    {
                      payment.image === 1
                        ? <i className={`fa fa-image ${styles.edit}`} onClick={() => viewImage2(payment)}></i>
                        : null
                    }
                  </td>
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                </tr>))
            }            
          </tbody>
        </Table>
      </div>
      <ModalPaymentImage code={code} id={paymentSelected.id} isOpen={viewImage} setIsOpen={setViewImage} />
    </div>
  )
}
