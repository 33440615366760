import React, { useEffect, useState } from 'react'
import styles from './AccessScreen.module.css'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/brand/logoAccess.png'
import { search } from '../../store/slices/group/thunks';
import { useDispatch } from 'react-redux';
import { Contact } from '../Contact/Contact';

export const AccessScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [code, setCode] = useState("")

  const search2 = () => {
    if (code) {
      const success = () => {
        navigate("/grupoInfo/")
      }
      dispatch(search(code, success))
    } else {
      Swal.fire("Acceso", "Escribe el codigo obtenido para continuar", "error");
    }
  }

  return (
    <div className={styles.mainDiv}>
      <div></div>
      <div className={styles.form}>
        <div className={styles.divLogo}>
          <img src={logo} alt="logo" className={styles.logo} />
        </div>
        <label className={`${styles.title} form-label mb-3`}>Consulta la información sobre tu grupo</label>
        <div className={`mb-2`}>
          <div className='row'>
            <div className="col-8">
              <input
                className='form-control'
                autoFocus
                placeholder='Escribe el codigo recibido'
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className='col-4'>
              <button className={`btn btn-primary`} style={{width:100}}
                onClick={() => search2()} >
                Entrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  )
}
