import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { addTableToSeatingPlan } from "../../../store/slices/group/thunks";
import { MySelect } from "../../ui/controls/MySelect";

export const AddTable = ({ idGroupFile, code, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const options = [
    { value: "10", label: "10" }, 
    { value: "12", label: "12" },
    { value: "16", label: "16" },
    { value: "20", label: "20" }
  ]
  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Mesa Al Plan De Mesas
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            chairs: { value: "10", label: "10" },
          }}
          onSubmit={(({ chairs
          }, { resetForm, setErrors }) => {
            if (!chairs) {
              return setErrors({ "chairs": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addTableToSeatingPlan(idGroupFile, code, chairs.value, success));
          })}
          validationSchema={
            Yup.object({
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Sillas:*' name="chairs" options={options} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("chairs", e);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Agregar
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
