import { viajesApi } from "../../../api/viajesApi";
import Swal from "sweetalert2";
import {
  setAdditionalList, setGroupFile, setGroupFilesList, setLodgingGroupList,
  setMasterSaleGroup,
  setPaymentScheme,
  setPendingAirportHotel,
  setPendingHotelAirport,
  setPendingPayments,
  setPendingSpecialRequest,
  setRoomingList,
  setRoomLodgingGroupList,
  setWaitingList,
  setWeedingPass
} from "./groupFilesSlice";
import fileDownload from 'js-file-download'

export const getAdditionalList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/additionalList");
      dispatch(setAdditionalList(resp.data.results));
    } catch (error) {

    }
  }
}

export const addGroupFile = (idCustomer, idCategory, name, tags, startDate, endDate, paydayLimit,
  lodging, passengers, rooms, additional, peopleQuantity, additionalPersonPrice, weddingPassPrice,
  suplementPrice, notes, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/groupFiles/addGroupFile", {
        idCustomer, idCategory, name, tags, startDate, endDate, paydayLimit, lodging, passengers,
        rooms, additional, peopleQuantity, additionalPersonPrice, weddingPassPrice,
        suplementPrice, notes,
      });
      success();
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }

  }
}

export const editGroupFile = (id, idCustomer, idCategory, name, tags, startDate, endDate, paydayLimit,
  lodging, passengers, rooms, additional, peopleQuantity, additionalPersonPrice,
  weddingPassPrice, suplementPrice, notes, isOpen, isVisible, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editGroupFile", {
        id, idCustomer, idCategory, name, tags, startDate, endDate, paydayLimit, lodging, passengers,
        rooms, additional, peopleQuantity, additionalPersonPrice,
        weddingPassPrice, suplementPrice, notes, isOpen, isVisible
      });
      success();
      dispatch(getGroupFileById(id));
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }

  }
}

export const getGroupFilesList = (file, isOpen) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/groupFilesList", {
        params: { file, isOpen }
      });
      dispatch(setGroupFilesList(resp.data.results));
    } catch (error) {

    }
  }
}

export const getGroupFileById = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/byId", {
        params: {
          id
        }
      });
      dispatch(setGroupFile(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
      dispatch(setGroupFile({}));
    }
  }
}

export const addLodgingGroupFile = (idGroupFile, checkInDate, checkOutDate, idLodgingType, reserve, locator,
  idHotel, idWholesaler, daysBefore, daysAfter, notes, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/groupFiles/addLodgingGroupFile", {
        idGroupFile, checkInDate, checkOutDate, idLodgingType, reserve, locator, idHotel, idWholesaler,
        daysBefore, daysAfter, notes,
      });
      success();
      dispatch(getLoadingGroup(idGroupFile));
      Swal.fire("Hospedaje", resp.data.msg, "success");
    } catch (error) {
    }
  }
}


export const editLodgingGroupFile = (id, checkInDate, checkOutDate, idLodgingType, reserve, locator, idHotel,
  idWholesaler, daysBefore, daysAfter, notes, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editLodgingGroupFile", {
        id, checkInDate, checkOutDate, idLodgingType, reserve, locator, idHotel, idWholesaler,
        daysBefore, daysAfter, notes,
      });
      success();
      dispatch(getLoadingGroup(idGroupFile));
      Swal.fire("Hospedaje", resp.data.msg, "success");
    } catch (error) {
      console.log(error);
    }
  }
}

export const deleteLodgingGroupFile = (id, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteLodgingGroupFile", {
        params: { id }
      });
      success();
      dispatch(getLoadingGroup(idGroupFile));
      Swal.fire("Hospedaje", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Hospedaje", error.response.data.msg, "error");
    }
  }
}

export const getLoadingGroup = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/lodgingGroupList", {
        params: {
          idGroupFile
        }
      });
      dispatch(setLodgingGroupList(resp.data.results));
    } catch (error) {
      dispatch(setLodgingGroupList([]));
    }
  }
}

export const addRoomLodgingGroup = (idLodgingGroup, room, adults, juniors, minors,
  infants, costPerNight, feeService, tax, others, fee, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/groupFiles/addRoomLodgingGroup", {
        idLodgingGroup, room, adults, juniors, minors,
        infants, costPerNight, feeService, tax, others, fee
      });
      success();
      dispatch(getRoomlodgingGroupList(idGroupFile));
      Swal.fire("Habitaciones", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Habitaciones", error.response.data.msg, "error");
    }
  }
}

export const editRoomLodgingGroup = (id, idLodgingGroup, room,
  costPerNight, feeService, tax, others, fee, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editRoomLodgingGroup", {
        id, idLodgingGroup, room, costPerNight, feeService, tax, others, fee
      });
      success();
      dispatch(getRoomlodgingGroupList(idGroupFile));
      Swal.fire("Habitaciones", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Habitaciones", error.response.data.msg, "error");
    }
  }
}

export const deleteRoomLodgingGroup = (id, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteRoomLodgingGroup", {
        params: { id }
      });
      success();
      dispatch(getRoomlodgingGroupList(idGroupFile));
      Swal.fire("Habitaciones", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Habitaciones", error.response.data.msg, "error");
    }
  }
}

export const getRoomlodgingGroupList = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/roomLodgingGroupList", {
        params: {
          idGroupFile
        }
      });
      dispatch(setRoomLodgingGroupList(resp.data.results));
    } catch (error) {
      dispatch(setRoomLodgingGroupList([]));
    }
  }
}

export const getMasterSaleGroup = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/masterSaleGroup", {
        params: {
          idGroupFile
        }
      });
      dispatch(setMasterSaleGroup(resp.data.results));
    } catch (error) {
      dispatch(setMasterSaleGroup({}));
    }
  }
}

export const addRoomMasterSaleGroup = (idMasterSaleGroupFile, idRoomLodgingGroup, quantity,
  idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/groupFiles/addRoomMasterSaleGroup", {
        idMasterSaleGroupFile, idRoomLodgingGroup, quantity
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const editRoomMasterSaleGroup = (id, idRoomLodgingGroup, quantity, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editRoomMasterSaleGroup", {
        id, idRoomLodgingGroup, quantity
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const deleteRoomMasterSaleGroup = (id, idGroupFile, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteRoomMasterSaleGroup", {
        params: { id }
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const addServiceMasterSale = (idMasterSaleGroupFile, idservice, idWholesaler, startDate, endDate, quantity,
  ticket, reserve, description, costPerUnit, feeService, tax, others, fee, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/groupFiles/addServiceMasterSale", {
        idMasterSaleGroupFile, idservice, idWholesaler, startDate, endDate, quantity,
        ticket, reserve, description, costPerUnit, feeService, tax, others, fee,
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const editServiceMasterSale = (id, idservice, idWholesaler, startDate, endDate, quantity,
  ticket, reserve, description, costPerUnit, feeService, tax, others, fee, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editServiceMasterSale", {
        id, idservice, idWholesaler, startDate, endDate, quantity,
        ticket, reserve, description, costPerUnit, feeService, tax, others, fee,
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const deleteServiceMasterSale = (id, idGroupFile, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteServiceMasterSale", {
        params: { id }
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const openCloseMasterSale = (idMasterSaleGroupFile, isOpen, idGroupFile) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/groupFiles/openCloseMasterSale", {
        idMasterSaleGroupFile, isOpen,
      });
      dispatch(getMasterSaleGroup(idGroupFile))
    } catch (error) {
    }
  }
}

export const addPaymentMasterSale = (idMasterSaleGroup, date, amount, idPaymentWay, reference, image,
  notes, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idMasterSaleGroup", idMasterSaleGroup);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("notes", notes ? notes : "");

      const resp = await viajesApi.post("/groupFiles/addPaymentMasterSale", form);
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const editPaymentMasterSale = (id, date, amount, idPaymentWay, reference, image,
  notes, approved, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("notes", notes ? notes : "");
      form.append("approved", approved);

      const resp = await viajesApi.put("/groupFiles/editPaymentMasterSale", form);
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Venta Maestra", resp.data.msg, "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Venta Maestra", error.response.data.msg, "error");
    }
  }
}

export const deletePaymentMasterSale = (id,
  idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deletePaymentMasterSale", {
        params: { id }
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const rejectPaymentMasterSale = (id, notes, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/rejectPaymentMasterSale", {
        params: { id, notes }
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const addPaymentWholesaler = (id, concept, date, amount, idPaymentWay, image, notes,
  idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {

      var form = new FormData();
      form.append("id", id);
      form.append("concept", concept);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      if (image)
        form.append("image", image, image.Name);
      form.append("notes", notes ? notes : "");
      const resp = await viajesApi.post("/groupFiles/addPaymentWholesaler", form);

      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const editPaymentWholesaler = (id, concept, date, amount, idPaymentWay, notes,
  idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editPaymentWholesaler", {
        id, concept, date, amount, idPaymentWay, notes
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const deletePaymentWholesaler = (id, concept, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deletePaymentWholesaler", {
        params: { id, concept }
      });
      success();
      dispatch(getMasterSaleGroup(idGroupFile));
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const roomingList = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/roomingList", {
        params: {
          idGroupFile
        }
      });
      dispatch(setRoomingList(resp.data.results));
    } catch (error) {
      dispatch(setRoomingList([]));
    }
  }
}

export const roomingListDetails = (idGroupFile, idHotel) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/roomingListDetails", {
        params: {
          idGroupFile, idHotel
        },
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'blob' },
      });
      fileDownload(resp.data, `Expediente #${idGroupFile} - Rooming List.xlsx`);
      Swal.fire("Archivos", "Rooming List descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getPendingPayments = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/pendingPayments",);
      dispatch(setPendingPayments(resp.data.results));
    } catch (error) {

    }
  }
}

export const editPendingPayment = (id, date, amount, idPaymentWay, reference, image,
  notes, approved, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("notes", notes ? notes : "");
      form.append("approved", approved);

      const resp = await viajesApi.put("/groupFiles/editPaymentMasterSale", form);
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const deletePendingPayment = (id, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deletePaymentMasterSale", {
        params: { id }
      });
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const rejectPendingPayment = (id, notes, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/rejectPaymentMasterSale", {
        params: { id, notes }
      });
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}


export const groupReservationVoucher = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/groupReservationVoucherById", {
        params: {
          id
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Comprobante de pago.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const addContract = (idGroupFile, contract, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idGroupFile", idGroupFile);
      if (contract)
        form.append("contract", contract, contract.Name);

      const resp = await viajesApi.post("/groupFiles/addContract", form);
      success();
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }
  }
}

export const addModificationPolicy = (idGroupFile, policy, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idGroupFile", idGroupFile);
      if (policy)
        form.append("policy", policy, policy.Name);

      const resp = await viajesApi.post("/groupFiles/addModificationPolicy", form);
      success();
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }
  }
}

export const addCancellationPolicy = (idGroupFile, policy, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idGroupFile", idGroupFile);
      if (policy)
        form.append("policy", policy, policy.Name);

      const resp = await viajesApi.post("/groupFiles/addCancellationPolicy", form);
      success();
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }
  }
}

export const deleteContract = (idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteContract", {
        params: { idGroupFile }
      });

      success();
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }
  }
}

export const deleteModificationPolicy = (idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteModificationPolicy", {
        params: { idGroupFile }
      });

      success();
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }
  }
}

export const deleteCancellationPolicy = (idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteCancellationPolicy", {
        params: { idGroupFile }
      });

      success();
      dispatch(getGroupFileById(idGroupFile));
      Swal.fire("Expediente De Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente De Grupo", error.response.data.msg, "error");
    }
  }
}

export const downloadContract = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/downloadContract", {
        params: {
          id
        },
        responseType: 'blob',
      });

      fileDownload(resp.data, `Contrato.pdf`);
      Swal.fire("Archivos", "Contrato descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadModificationPolicy = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/downloadModificationPolicy", {
        params: {
          id
        },
        responseType: 'blob',
      });

      fileDownload(resp.data, `Politica de modificacion.pdf`);
      Swal.fire("Archivos", "Politica de modificacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadCancellationPolicy = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/downloadCancellationPolicy", {
        params: {
          id
        },
        responseType: 'blob',
      });

      fileDownload(resp.data, `Politica de cancelacion.pdf`);
      Swal.fire("Archivos", "Politica de cancelacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getPendingAirportHotel = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/pendingAirportHotel",);
      dispatch(setPendingAirportHotel(resp.data.results));
    } catch (error) {

    }
  }
}

export const editAirportHotel = (id, notes, statusRequest, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editRequestAirportHotel", {
        id, notes, statusRequest
      });
      success();
      dispatch(getPendingAirportHotel());
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const getPendingHotelAirport = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/pendingHotelAirport",);
      dispatch(setPendingHotelAirport(resp.data.results));
    } catch (error) {

    }
  }
}

export const editHotelAirport = (id, notes, statusRequest, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editRequestHotelAirport", {
        id, notes, statusRequest
      });
      success();
      dispatch(getPendingHotelAirport());
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const getApprovedRequestAirportHotel = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/approvedRequestAirportHotel", {
        params: {
          idGroupFile
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Aeropuerto-Hotel-${idGroupFile}.xlsx`);
      Swal.fire("Archivos", "Transporte descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getApprovedRequestHotelAirport = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/approvedRequestHotelAirport", {
        params: {
          idGroupFile
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Hotel-Aeropuerto-${idGroupFile}.xlsx`);
      Swal.fire("Archivos", "Transporte descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getTransportChurch = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/transportChurch", {
        params: {
          idGroupFile
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Iglesia-${idGroupFile}.xlsx`);
      Swal.fire("Archivos", "Transporte descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getAgencyPaymentScheme = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/agencyPaymentScheme", {
        params: {
          idGroupFile
        }
      });
      dispatch(setPaymentScheme(resp.data.results));
    } catch (error) {
      dispatch(setPaymentScheme([]));
    }
  }
}

export const addAgencyPaymentScheme = (idGroupFile, topic, amount, date, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/groupFiles/addAgencyPaymentScheme", {
        idGroupFile, topic, amount, date
      });
      success();
      dispatch(getAgencyPaymentScheme(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}


export const editAgencyPaymentScheme = (id, topic, amount, date, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editAgencyPaymentScheme", {
        id, topic, amount, date
      });
      success();
      dispatch(getAgencyPaymentScheme(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const deleteAgencyPaymentScheme = (id, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteAgencyPaymentScheme", {
        params: { id, idGroupFile }
      });

      success();
      dispatch(getAgencyPaymentScheme(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const getWeedingPass = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/weedingPass", {
        params: {
          idGroupFile
        }
      });
      dispatch(setWeedingPass(resp.data.results));
    } catch (error) {
      dispatch(setWeedingPass([]));
    }
  }
}

export const addWeedingPass = (idGroupFile, name, birthdate, amount, status, image, stayAtHotel, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idGroupFile", idGroupFile);
      form.append("name", name);
      form.append("birthdate", birthdate);
      form.append("amount", amount);
      form.append("status", status);
      if (image)
        form.append("image", image, image.Name);
      form.append("stayAtHotel", stayAtHotel);

      const resp = await viajesApi.post("/groupFiles/addWeedingPass", form);

      success();
      dispatch(getWeedingPass(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const editWeedingPass = (id, name, birthdate, amount, status, image, stayAtHotel,
   idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("name", name);
      form.append("birthdate", birthdate);
      form.append("amount", amount);
      form.append("status", status);
      if (image)
        form.append("image", image, image.Name);
      form.append("stayAtHotel", stayAtHotel);

      const resp = await viajesApi.put("/groupFiles/editWeedingPass", form);
      success();
      dispatch(getWeedingPass(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const deleteWeedingPass = (id, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteWeedingPass", {
        params: { id, idGroupFile }
      });

      success();
      dispatch(getWeedingPass(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const weedingPassListDetails = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/weedingPassListDetails", {
        params: {
          idGroupFile
        },
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'blob' },
      });
      fileDownload(resp.data, `Expediente #${idGroupFile} - Weeeding Pass List.xlsx`);
      Swal.fire("Archivos", "Weeeding Pass List descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getWaitingList = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/waitingList", {
        params: {
          idGroupFile
        }
      });
      dispatch(setWaitingList(resp.data.results));
    } catch (error) {
      dispatch(setWaitingList([]));
    }
  }
}

export const addWaitingList = (idGroupFile, name, room, quantity, checkInDate, checkOutDate, success) => {

  return async (dispatch, getState) => {

    try {

      const resp = await viajesApi.post("/groupFiles/addWaitingList", {
        idGroupFile, name, room, quantity, checkInDate, checkOutDate
      });

      success();
      dispatch(getWaitingList(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const editWaitingList = (id, name, room, quantity, checkInDate, checkOutDate, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editWaitingList", {
        id, name, room, quantity, checkInDate, checkOutDate
      });
      success();
      dispatch(getWaitingList(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const deleteWaitingList = (id, idGroupFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/groupFiles/deleteWaitingList", {
        params: { id, idGroupFile }
      });

      success();
      dispatch(getWaitingList(idGroupFile));
      Swal.fire("Grupo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Grupo", error.response.data.msg, "error");
    }
  }
}

export const downloadPayments = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/downloadPayments", {
        params: {
          idGroupFile
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Pagos.zip`);
      Swal.fire("Archivos", "Pagos descargados correctamente", "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getPendingSpecialRequest = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/groupFiles/pendingSpecialRequest",);
      dispatch(setPendingSpecialRequest(resp.data.results));
    } catch (error) {

    }
  }
}

export const editSpecialRequest = (id, answer, statusRequest, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/groupFiles/editSpecialRequest", {
        id, answer, statusRequest
      });
      success();
      dispatch(getPendingSpecialRequest());
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}